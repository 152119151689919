.btn-link, a {
    text-decoration: none !important;
    color: #6f42c1;
}

.rbc-time-header-cell-single-day {
    display: block !important;
}

/* .rbc-allday-cell {
    display: none !important;
} */

.rbc-time-view .rbc-header {
    border-bottom: none;
    /*noinspection CssInvalidPropertyValue*/
    text-wrap: wrap;
}

.rbc-today {
    background-color: #e3dfec !important;
}

.rbc-time-view-resources .rbc-header {
    width: auto !important;
    min-width: 140px;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid #AAA !important;
}

.rbc-header, .rbc-day-bg {
    border-left: 1px solid #AAA !important;
}

.rbc-header {
    height: min-content !important;
}

.rbc-event-content {
    white-space: normal !important;
}

.rbc-current-time-indicator {
    background-color: #2d3820 !important;
    height: 2px !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
    background-color: #99258b !important;
}

.primary-box {
    background-color: #99258b !important;
}

.secondary-box {
    background-color: #53286e !important;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;
    color: black;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.contact-street-address {
    flex: 0 0 75%
}

.input-group-autosuggest {
    flex: 1
}

.form-control::-webkit-input-placeholder {
    color: rgb(194, 194, 194) !important;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
    color: rgb(194, 194, 194) !important;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
    color: rgb(194, 194, 194) !important;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
    color: rgb(194, 194, 194) !important;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
    color: rgb(194, 194, 194) !important;
}

/* Microsoft Edge */

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 100 !important;
}

.DraftEditor-editorContainer {
    background-color: #fff;
    overflow-y: auto;
    padding: 10px;
}

.public-DraftEditor-content {
    min-height: 200px;
}

.DraftEditor-editorContainer a {
    color: blue !important;
    text-decoration: underline !important;
}

.display-html a {
    color: blue !important;
    text-decoration: underline !important;
}

.rbt-borderless tr {
    border-top-style: hidden !important;
}

.rbt-borderless tr {
    border-bottom-style: hidden !important;
}

.react-bs-table td {
    outline: none !important;
}

.swal2-modal {
    padding: 0 !important;
}


.swal2-header {
    align-items: start !important;
    padding: 1rem !important;
}

.swal2-actions {
    justify-content: flex-end !important;
    padding: 1rem !important;
}

.swal2-content {
    text-align: left !important;
    font-size: 0.875rem !important;
}

select.swal2-select:focus {
    border-color: #99258b;
    /*noinspection CssInvalidFunction*/
    box-shadow: 0 0 0 0.2rem rgb(153 37 139 / 25%) !important;
}

.swal2-validation-message {
    margin: 0 !important;
}

.progress-icon {
    background: #53286e;
    padding: 6.75px 5px;
    border-radius: 20px;
    color: white;
    height: 27.5px;
}

.Toastify__toast--warning {
    background: #caa30e !important;
}

.highlighted {
    background-color: yellow;
    padding: 0;
}

.clickable {
    cursor: pointer;
}

.jump-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    height: 37px !important;
}

